import React, { useEffect, useState } from 'react';
import { createOrder, getUserCart, getMenuItems, removeFromCart } from '../services/api';
import { ReactComponent as TrashIcon } from './trash.svg';
import Loader from './CartLoader'; // Импортируем компонент Loader

const CartSidebar = ({ isOpen, setIsOpen, userId, fetchUserCart }) => {
  const [cart, setCart] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (userId && isOpen) {
      fetchMenuAndCart();
    }
  }, [userId, isOpen]);

  const fetchMenuAndCart = async () => {
    try {
      setLoading(true);
      const [menuResponse, cartResponse] = await Promise.all([getMenuItems(userId), getUserCart(userId)]);
      const menuData = menuResponse.data.menu.flatMap(category => category.Products);
      const cartData = cartResponse.data;

      setMenuItems(menuData);

      const updatedCart = [];

      // Обработка обычных товаров
      if (cartData.products && cartData.products.length > 0) {
        const productItems = cartData.products.map(cartItem => {
          const product = menuData.find(item => item.productId === cartItem.productId);

          if (product) {
            // Логика для работы с вариантами товара, которые привязаны к модификаторам
            let productPrice = product.price || 0;
            let variant = null;

            if (product.productVariants) {
              variant = product.productVariants.find(v =>
                v.optionIds.some(optionId => cartItem.modifierIds.includes(optionId))
              );
              if (variant) {
                productPrice = variant.salePrice && variant.salePrice > 0 
                  ? variant.salePrice 
                  : variant.price; // Если найден вариант товара, используем его цену
              }
            }

            const modifiers = cartItem.modifierIds.map(modifierId => {
              return product.optionCategories
                ?.flatMap(category => category.options)
                ?.find(option => option.optionId === modifierId) || {};
            }).filter(Boolean);

            const modifiersPrice = modifiers.reduce((total, modifier) => total + (modifier.price || 0), 0);
            const totalPrice = productPrice + modifiersPrice; // Учитываем модификаторы, но не скидку на них

            return {
              ...product,
              ...cartItem,
              modifiers,
              variant, // Сохраняем выбранный вариант
              totalPrice,
            };
          } else {
            return { ...cartItem, name: 'Неизвестный товар', image: 'placeholder.jpg' };
          }
        });

        updatedCart.push(...productItems);
      }

      // Обработка комбо товаров
      if (cartData.combos && cartData.combos.length > 0) {
        const comboItems = cartData.combos.map(combo => {
          const firstProduct = combo.products[0];
          const productImage = menuData.find(item => item.productId === firstProduct.productId)?.imageFileName || 'placeholder.jpg';

          return {
            ...combo,
            imageFileName: productImage,
            totalPrice: combo.price || 0,
          };
        });

        updatedCart.push(...comboItems);
      }

      setCart(updatedCart);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching menu and cart:', error);
      setLoading(false);
    }
  };

  const handleRemove = (orderproductId, isCombo) => {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }

    removeFromCart(userId, orderproductId, 1)
      .then(() => {
        setCart(prevCart => {
          if (isCombo) {
            return prevCart.filter(cartItem => cartItem.productID !== orderproductId);
          } else {
            return prevCart.filter(cartItem => cartItem.orderproductId !== orderproductId);
          }
        });
        fetchUserCart(userId);
      })
      .catch(error => {
        console.error('Error removing item from cart:', error);
      });
  };

  const handleOrderSubmit = () => {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }

    createOrder(userId)
      .then((response) => {
        if (response.status === 200) {
          setIsOpen(false);
          window.Telegram.WebApp.close();
        }
      })
      .catch(error => {
        console.error('Error creating order:', error);
        window.Telegram.WebApp.close();
      });
  };

  // Функция для подсчета итоговой суммы всей корзины с учётом скидок
  const calculateTotalSum = () => {
    return cart.reduce((sum, item) => {
      const basePrice = item.variant 
        ? (item.variant.salePrice && item.variant.salePrice > 0 ? item.variant.salePrice : item.variant.price)
        : (item.salePrice && item.salePrice > 0 ? item.salePrice : item.price);
    
      const modifiersPrice = (item.modifiers || []).reduce((total, modifier) => total + (modifier.price || 0), 0);
      return sum + basePrice + modifiersPrice;
    }, 0);
  };
  
  return (
    <div id="cart-sidebar" className={`cart-sidebar ${(isOpen) ? 'open' : ''}`}>
      <div className="cart-header">
        <h2>Корзина</h2>
        <button id="close-cart" className="close-cart" onClick={() => setIsOpen(false)}>Закрыть</button>
      </div>
      {
        isLoading && (<Loader />)
      }
      {
        !isLoading && (
          <div className='card-content'>
            <div id="cart-items" className="cart-items">
              {cart.length > 0 ? (
                cart.map((item, index) => {
                  const basePrice = item.variant 
                    ? (item.variant.salePrice && item.variant.salePrice > 0 ? item.variant.salePrice : item.variant.price) 
                    : (item.salePrice && item.salePrice > 0 ? item.salePrice : item.price);
                
                  const modifiersPrice = (item.modifiers || []).reduce((total, modifier) => total + (modifier.price || 0), 0);
                  const totalPrice = basePrice + modifiersPrice;
                
                  return (
                    <div key={index} className="cart-item">
                      <img
                        className="cart-item-image"
                        src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName}`}
                        alt={item.name || 'Товар'}
                      />
                      <div className="cart-item-details">
                        <h3 className="cart-item-name">{item.name}</h3>
                        <p className="cart-item-price">
                          {item.salePrice && item.salePrice > 0 ? (
                            <>
                              <span style={{ textDecoration: 'line-through' }}>{item.price} ₽</span>
                              <span> {totalPrice} ₽</span>
                            </>
                          ) : (
                            <span>{totalPrice} ₽</span>
                          )}
                        </p>
                      </div>
                      <div className="cart-item-trash-icon">
                        <TrashIcon
                          style={{ width: "32px", height: "32px" }}
                          onClick={() => handleRemove(item.productID || item.orderproductId, !!item.products)}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Корзина пуста</p>
              )}
            </div>
            <div className="cart-footer">
              <p id="total-sum">Итого: {calculateTotalSum()} ₽</p>
              <button className="order-button" disabled={cart.length === 0} onClick={handleOrderSubmit}>Оформить заказ</button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CartSidebar;
