// src/components/CartButton.js
import React from 'react';
import { ReactComponent as CartIcon } from './cart.svg';
import styles from './CartButton.module.css'; // Импортируем стили как модуль

const CartButton = ({ onClick, itemCount }) => (
  <div className={styles.cartButton} onClick={onClick}>
    <CartIcon className={styles.cartIcon} />
    {itemCount > 0 && <span className={styles.itemCount}>{itemCount}</span>} {/* Кружок с количеством товаров */}
  </div>
);

export default CartButton;