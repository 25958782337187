import React, { useState, useEffect } from 'react';
import { getUserOrders, getOrderHistoryByUser, getMenuItems, submitOrderRating } from '../services/api';
import Loader from './Loader'; 
import './Orders.css'; 

const telegram = window.Telegram.WebApp;
const user = telegram.initDataUnsafe.user;
const authToken = telegram.initData;

const Orders = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [rating, setRating] = useState(0);
  const [ratingComment, setRatingComment] = useState('');

  useEffect(() => {
    if (userId) {
      fetchMenuAndOrders();
    }
  }, [userId]);

  const fetchMenuAndOrders = async () => {
    try {
      setLoading(true);
      const [menuResponse, ordersResponse] = await Promise.all([getMenuItems(user.id), getUserOrders(userId)]);
      const menuData = menuResponse.data.menu.flatMap(category => category.Products);

      setMenuItems(menuData);

      const updatedOrders = ordersResponse.data.map(order => ({
        ...order,
        products: order.products.map(product => enrichProductWithMenuData(product, menuData)),
        combos: order.combos.map(combo => enrichComboWithMenuData(combo, menuData)) // Обработка комбо
      }));

      setOrders(updatedOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching menu and orders:', error);
      setLoading(false);
    }
  };

  const enrichProductWithMenuData = (product, menuData) => {
    const menuProduct = menuData.find(menuItem => menuItem.productId === product.productId);

    if (menuProduct) {
      // Обработка вариантов товара
      let productPrice = menuProduct.price || 0;
      if (menuProduct.productVariants) {
        const selectedVariant = menuProduct.productVariants.find(variant =>
          variant.optionIds.some(optionId => product.modifierIds.includes(optionId))
        );
        if (selectedVariant) {
          productPrice = selectedVariant.price;
        }
      }
  
      const modifiers = product.modifierIds.map(modifierId => {
        return menuProduct.optionCategories
          ?.flatMap(category => category.options)
          ?.find(option => option.optionId === modifierId) || {};
      }).filter(Boolean);

      const modifiersPrice = modifiers.reduce((total, modifier) => total + (modifier.price || 0), 0);
      const totalPrice = productPrice + modifiersPrice;

      return {
        ...menuProduct,
        ...product,
        modifiers,
        totalPrice,
      };
    } else {
      console.warn(`Product with ID ${product.productId} not found in menu`);
      return { ...product, name: 'Неизвестный товар', imageFileName: 'placeholder.jpg', totalPrice: 0 };
    }
  };

  const enrichComboWithMenuData = (combo, menuData) => {
    const menuCombo = menuData.find(menuItem => menuItem.productId === combo.programId);

    if (menuCombo) {
      return {
        ...menuCombo,
        ...combo,
        name: menuCombo.name || combo.name,
        price: menuCombo.price || combo.price,
        imageFileName: menuCombo.imageFileName || 'placeholder.jpg',
      };
    } else {
      return { ...combo, name: 'Неизвестное комбо', imageFileName: 'placeholder.jpg', price: 0 };
    }
  };

  const fetchOrderHistory = async () => {
    try {
      setLoading(true);
      const response = await getOrderHistoryByUser(userId);
      const updatedHistory = response.data.map(order => ({
        ...order,
        products: order.products.map(product => enrichProductWithMenuData(product, menuItems)),
        combos: order.combos.map(combo => enrichComboWithMenuData(combo, menuItems)),
      }));

      setOrderHistory(updatedHistory);
      setShowHistory(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order history:', error);
      setLoading(false);
    }
  };

  const openRatingModal = (order) => {
    setCurrentOrder(order);
    setRating(order.rating || 0);
    setRatingComment(order.rating_comment || '');
    setIsRatingModalOpen(true);
  };

  const closeRatingModal = () => {
    setIsRatingModalOpen(false);
    setCurrentOrder(null);
    setRating(0);
    setRatingComment('');
  };

  const submitRating = async () => {
    try {
      await submitOrderRating(currentOrder.order_id, rating, ratingComment);
      const updatedOrders = orders.map(order =>
        order.id === currentOrder.id ? { ...order, rating, rating_comment: ratingComment } : order
      );
      setOrders(updatedOrders);
      closeRatingModal();
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleClose = () => {
    window.Telegram.WebApp.close();
  };

  const calculateOrderTotal = (order) => {
    const productsTotal = order.products.reduce((total, item) => total + (item.totalPrice * item.amount), 0);
    const combosTotal = order.combos.reduce((total, item) => total + (item.price * item.amount), 0);
    return productsTotal + combosTotal;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="orders-container">
      <button className="close-button" onClick={handleClose}>Закрыть</button>
      <h2>Текущие заказы</h2>
      {orders.length > 0 ? (
        <div>
          {orders.map((order, index) => (
            <div key={index} className="orders-container">
              <h3>Заказ № {order.order_id.slice(0, 8)}</h3>
              <h3>Статус заказа: {order.status}</h3>
              {order.products.map((item, idx) => (
                <div key={idx} className="order-item">
                  <img className="order-item-image" src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName}`} alt={item.name} />
                  <div className="order-item-details">
                    <div className="order-item-name">{item.name}</div>
                    <div className="order-item-price">{item.totalPrice} руб.</div>
                    <ul className="order-item-modifiers">
                      {item.modifiers.map((modifier, modIdx) => (
                        <li key={modIdx} className="modifier">
                          {modifier.restrictions?.isDefault ? '+' : '-'} {modifier.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="order-item-quantity">{item.amount}</div>
                </div>
              ))}
              {order.combos.map((combo, idx) => (
                <div key={idx} className="order-item">
                  <img className="order-item-image" src={`https://novosibirsk.grilnica.ru/cdn/${combo.imageFileName}`} alt={combo.name} />
                  <div className="order-item-details">
                    <div className="order-item-name">{combo.name}</div> {/* Отображаем имя комбо */}
                    <div className="order-item-price">{combo.price} руб.</div> {/* Отображаем цену комбо */}
                  </div>
                  <div className="order-item-quantity">{combo.amount}</div> {/* Количество комбо */}
                </div>
              ))}
              <p>Сумма заказа: {calculateOrderTotal(order)} руб.</p>
            </div>
          ))} 
        </div>
      ) : (
        <p>Нет текущих заказов</p>
      )}
      {showHistory ? (
        <div className="order-history">
          <h2>История заказов</h2>
          {orderHistory.map((order, index) => (
            <div key={index} className="orders-container">
              <h3>Заказ № {order.order_id.slice(0, 8)}</h3>
              <h3>Статус заказа: {order.status}</h3>
              {order.products.map((item, idx) => (
                <div key={idx} className="order-item">
                  <img className="order-item-image" src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName}`} alt={item.name} />
                  <div className="order-item-details">
                    <div className="order-item-name">{item.name}</div>
                    <div className="order-item-price">{item.totalPrice} руб.</div> {/* Здесь используем totalPrice */}
                    <ul className="order-item-modifiers">
                      {item.modifiers.map((modifier, modIdx) => (
                        <li key={modIdx} className="modifier">
                          {modifier.restrictions?.isDefault ? '+' : '-'} {modifier.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="order-item-quantity">{item.amount}</div>
                </div>
              ))}
              {order.combos.map((combo, idx) => (
                <div key={idx} className="order-item">
                  <img className="order-item-image" src={`https://novosibirsk.grilnica.ru/cdn/${combo.imageFileName}`} alt={combo.name} />
                  <div className="order-item-details">
                    <div className="order-item-name">{combo.name}</div> {/* Отображаем имя комбо */}
                    <div className="order-item-price">{combo.price} руб.</div> {/* Отображаем цену комбо */}
                  </div>
                  <div className="order-item-quantity">{combo.amount}</div> {/* Количество комбо */}
                </div>
              ))}
              <p>Сумма заказа: {calculateOrderTotal(order)} руб.</p>
              {order.rating ? (
                <div className="order-rating">
                  {'⭐'.repeat(order.rating)}
                  {'☆'.repeat(5 - order.rating)}
                </div>
              ) : (
                <button onClick={() => openRatingModal(order)}>Оценить заказ</button>
              )}
            </div>
          ))} 
        </div>
      ) : (
        <button className="history-button" onClick={fetchOrderHistory}>
          Показать историю заказов
        </button>
      )}

      {isRatingModalOpen && (
        <div className="modal-overlay" onClick={closeRatingModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Оцените заказ</h2>
            <div>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => setRating(star)}
                  className={star <= rating ? 'star star-filled' : 'star star-empty'}
                >
                  {star <= rating ? '⭐' : '☆'}
                </span>
              ))}
            </div>
            <textarea
              placeholder="Оставьте комментарий"
              value={ratingComment}
              onChange={(e) => setRatingComment(e.target.value)}
              rows={4}
              onClick={(e) => e.stopPropagation()} // Этот обработчик предотвращает закрытие окна
            />
            <button onClick={submitRating}>Принять</button>
            <button onClick={closeRatingModal}>Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;