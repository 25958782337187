// src/components/ProductDetailsPageAlt.js
import React, { useState, useEffect } from 'react';
import './ProductDetailsPage.css'; // Используем те же стили
import ComboProductDetails from './ComboProductDetails';
import { useParams } from 'react-router-dom';
import { fetchProductById, fetchWeightMap, addToCartAlt } from '../services/api'; // Импортируем необходимые функции

const telegram = window.Telegram.WebApp;
const user = telegram.initDataUnsafe.user;
const authToken = telegram.initData;
const ProductDetailsPageAlt = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryRestrictions, setCategoryRestrictions] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0); // Добавляем состояние для веса
  const [selectedCombos, setSelectedCombos] = useState([]);
  const [weightMap, setWeightMap] = useState({}); // Состояние для карты весов

  useEffect(() => {
    // Получаем карту весов при монтировании компонента
    const loadWeightMap = async () => {
      try {
        const response = await fetchWeightMap();
        setWeightMap(response.data); // Сохраняем карту весов
        console.log('Загружена карта весов:', response.data); // Лог для проверки
      } catch (error) {
        console.error('Ошибка загрузки карты весов:', error);
      }
    };

    loadWeightMap();
  }, []);

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const data = await fetchProductById(productId, user.id);
        setProduct(data);

        if (data.productVariants && data.productVariants.length > 0) {
          const defaultVariant = data.productVariants.find(variant => 
            variant.optionIds.some(optionId => 
              data.optionCategories.some(category =>
                category.options.some(option => option.optionId === optionId && option.restrictions?.isDefault)
              )
            )
          ) || data.productVariants[0]; // Если нет, выбираем первый

          setSelectedVariant(defaultVariant);
          setSelectedOptions(defaultVariant.optionIds); // Устанавливаем модификаторы для варианта
          updateCategoryRestrictions(defaultVariant, data);
        } else if (data.optionCategories) {
          const defaultRestrictions = {};
          data.optionCategories.forEach(optionCategory => {
            const { min, max } = getOptionRestrictions(optionCategory);
            defaultRestrictions[optionCategory.optionCategoryId] = { min, max };
          });
          setCategoryRestrictions(defaultRestrictions);
        } else {
          setTotalPrice(data.price || 0);
        }

        // Логика для модификаторов по умолчанию
        const defaultOptions = [];
        data.optionCategories?.forEach(optionCategory => {
          let hasDefaultOption = false;
          optionCategory.options.some(option => {
            if (option.restrictions && option.restrictions.isDefault && !hasDefaultOption) {
              defaultOptions.push(option.optionId);
              hasDefaultOption = true;
              return true;
            }
            return false;
          });
        });

        setSelectedOptions(prevOptions => {
          const combinedOptions = [...prevOptions, ...defaultOptions];
          const uniqueOptions = combinedOptions.filter((option, index) => combinedOptions.indexOf(option) === index);
          return uniqueOptions;
        });
      } catch (error) {
        console.error('Ошибка загрузки продукта:', error);
      }
    };

    loadProduct();
  }, [productId]);

  useEffect(() => {
    // Пересчет веса при изменении модификаторов или карты весов
    if (weightMap && selectedOptions.length > 0) {
      const newWeight = getOptionsWeight(selectedOptions, weightMap, product, selectedVariant);
      console.log('Обновленный вес (с учетом модификаторов):', newWeight); // Лог для отладки
      setTotalWeight((newWeight || 0).toFixed(2));
    } else if (product && !product.productVariants) {
      // Если нет вариантов продукта, используем вес самого продукта и модификаторов
      const newWeight = getOptionsWeight(selectedOptions, weightMap, product, null);
      console.log('Вес без вариантов (с модификаторами):', newWeight);
      setTotalWeight((newWeight || product.weight || 0).toFixed(2)); // Учитываем вес товара, если он указан
    }
  }, [selectedOptions, weightMap, product, selectedVariant]);

  useEffect(() => {
    if (product) {
      calculateTotalPrice();
    }
  }, [selectedVariant, selectedOptions, product]);

  const updateCategoryRestrictions = (variant, productData = product) => {
    const newRestrictions = {};

    productData.optionCategories?.forEach(optionCategory => {
      const { min, max } = getOptionRestrictions(optionCategory, variant);
      newRestrictions[optionCategory.optionCategoryId] = { min, max };
    });

    setCategoryRestrictions(newRestrictions);
  };

  const getOptionRestrictions = (optionCategory, variant = selectedVariant) => {
    if (optionCategory.restrictions && optionCategory.restrictions.min !== null && optionCategory.restrictions.max !== null) {
      return {
        min: optionCategory.restrictions.min,
        max: optionCategory.restrictions.max,
      };
    } else if (variant && optionCategory.productVariantRestrictions) {
      const variantRestrictions = optionCategory.productVariantRestrictions.productVariantId[variant.productVariantId];
      if (variantRestrictions) {
        return {
          min: variantRestrictions.min !== undefined ? variantRestrictions.min : 1,
          max: variantRestrictions.max !== undefined ? variantRestrictions.max : 10,
        };
      }
    }
    return { min: 0, max: 10 };
  };

  const calculateTotalPrice = () => {
    let price = selectedVariant ? selectedVariant.price : product.price;

    selectedOptions.forEach(optionId => {
      const option = product.optionCategories?.flatMap(category => category.options).find(option => option.optionId === optionId);
      if (option && option.price) {
        price += option.price;
      }
    });

    setTotalPrice(price);
  };

  const getOptionsWeight = (optionIds, weightMap, product, selectedVariant = null) => {
    if (!optionIds || !weightMap || !product) {
      return undefined;
    }
  
    let resultWeight = 0;
  
    // Если у товара есть варианты и выбран вариант, используем его вес
    if (selectedVariant && selectedVariant.weight) {
      resultWeight += selectedVariant.weight;
    } else if (product.weight) {
      // Если у товара нет вариантов, используем вес самого продукта
      resultWeight += product.weight;
    }
  
    // Проходим по всем выбранным модификаторам и добавляем их вес
    for (const optionId of optionIds) {
      let optionWeight;
      const weights = weightMap[optionId];
  
      if (weights) {
        // Проверяем зависимость веса от других модификаторов (dependsOnObjectId)
        for (const weight of weights) {
          if (optionIds.includes(weight.dependsOnObjectId)) {
            optionWeight = weight.weight;
            break;
          }
        }
  
        // Если не нашли зависимости, берем вес без зависимости (dependsOnObjectId: null)
        if (optionWeight === undefined) {
          optionWeight = weights.find((w) => !w.dependsOnObjectId)?.weight;
        }
  
        // Добавляем вес модификатора
        resultWeight += optionWeight ?? 0;
      }
    }
  
    return resultWeight;
  };

  const handleOptionSelect = (optionId, max, min, optionCategoryId) => {
    const categoryOptions = product.optionCategories.find(category => category.optionCategoryId === optionCategoryId)?.options || [];
    const selectedInCategory = selectedOptions.filter(id => categoryOptions.some(option => option.optionId === id));

    // Проверяем, является ли опция вариантом товара (например, бортик)
    const isVariantOption = product.productVariants?.some(variant => variant.optionIds.includes(optionId));

    // Если это вариант товара
    if (isVariantOption) {
      handleVariantSelect(product.productVariants.find(variant => variant.optionIds.includes(optionId)));
      return; // Выходим, так как обработка варианта завершена
    }

    // Логика для min = 1 и max = 1, а также для min = 0 и max = 1
    if ((min === 1 && max === 1) || (min === 0 && max === 1)) {
      if (selectedInCategory.includes(optionId)) {
        if (min === 0) {
          // Убираем выбранную опцию, если min = 0 (разрешаем "отжатие")
          setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
        return; // Не даём "отжать" модификатор, если min = 1
      }

      // Убираем старые опции и выбираем новую
      const newSelected = selectedOptions.filter(id => !categoryOptions.some(option => option.optionId === id));
      setSelectedOptions([...newSelected, optionId]);
    } else {
      // Логика для категорий с другими ограничениями
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter(id => id !== optionId)); // Убираем, если уже выбрано
      } else if (selectedInCategory.length < max) {
        setSelectedOptions([...selectedOptions, optionId]); // Добавляем новую опцию
      }
    }

    // Логика для предотвращения выбора одинаковых опций
    let currentSelectedModifiers = selectedOptions
      .map(selectedId => product.optionCategories.flatMap(cat => cat.options).find(option => option.optionId === selectedId))
      .filter(Boolean);

    const selectedOption = product.optionCategories
      .flatMap(cat => cat.options)
      .find(option => option.optionId === optionId);

    if (!selectedOption) return;

    // Проверяем, есть ли модификаторы с таким же именем в других категориях
    const conflictingOption = currentSelectedModifiers
      .find(option => option.name === selectedOption.name && option.optionCategoryId !== optionCategoryId);

    if (conflictingOption) {
      setSelectedOptions(prevOptions => prevOptions.filter(id => id !== conflictingOption.optionId));
    }
  };

  const handleVariantSelect = (variant) => {
    if (!variant) return;
    setSelectedVariant(variant);
    updateCategoryRestrictions(variant);
    filterExcessOptions(variant);
    setSelectedOptions(prevOptions => {
      const optionsWithoutOldVariant = prevOptions.filter(optionId => !selectedVariant?.optionIds.includes(optionId));
      return [...optionsWithoutOldVariant, ...variant.optionIds];
    });
  };

  const filterExcessOptions = (variant) => {
    const updatedOptions = [...selectedOptions];

    product.optionCategories?.forEach(optionCategory => {
      const { max } = getOptionRestrictions(optionCategory, variant);

      const categoryOptions = optionCategory.options.map(option => option.optionId);
      const selectedInCategory = updatedOptions.filter(optionId => categoryOptions.includes(optionId));

      while (selectedInCategory.length > max) {
        const optionToRemove = selectedInCategory.pop();
        updatedOptions.splice(updatedOptions.indexOf(optionToRemove), 1);
      }
    });

    setSelectedOptions(updatedOptions);
  };

  const handleComboChange = (combos) => {
    setSelectedCombos(combos); // Сохраняем выбранные комбо
  };

  const handleAddToCartAlt = () => {
    const allCategoriesValid = product.optionCategories?.every(optionCategory => {
      const selectedInCategory = selectedOptions.filter(optionId =>
        optionCategory.options.some(option => option.optionId === optionId)
      );

      const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };
      const count = selectedInCategory.length;
      return count >= min && count <= max;
    });

    if (allCategoriesValid || !product.optionCategories) {
      const productToAdd = {
        products: [
          {
            modifierIds: selectedOptions,
            productId: product.productId,
            comment: "",
            amount: 1,
            isExcise: product.isExcise || false,
          },
        ],
        combos: selectedCombos.map(combo => ({
          ...combo,
          programId: product.productId, // Устанавливаем programId как айди продукта
          name: product.name,
          price:product.price
        })),
      };

      addToCartAlt(productToAdd)
        .then(response => {
          console.log('Товар успешно добавлен в корзину:', response);
          if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.close();
          }
        })
        .catch(error => {
          console.error('Ошибка при добавлении товара в корзину:', error);
          alert('Ошибка при добавлении товара в корзину.');
        });
    } else {
      alert('Пожалуйста, выберите допустимое количество опций в каждой категории.');
    }
  };

  if (!product) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="product-page">
      <h1>{product.name}</h1>
      <p>{product.description}</p>
      <img src={`https://novosibirsk.grilnica.ru/cdn/${product.imageFileName}`} alt={product.name} />
        <>
          {totalPrice > 0 && <p>Цена: {totalPrice} руб.</p>}
          {totalWeight > 0 && <p>Вес: {totalWeight} кг</p>}
        </>
      {product.comboGroupList && (
        <ComboProductDetails comboGroupList={product.comboGroupList} onComboChange={handleComboChange} />
      )}

      {product.optionCategories && (
        <div className="options-container">
          {product.optionCategories.map(optionCategory => {
            const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };

            return (
              <div key={optionCategory.optionCategoryId} className="option-category">
                <h3>{optionCategory.name}</h3>
                <div className="options">
                  {optionCategory.options.map(option => (
                    <div
                      key={option.optionId}
                      className={`option ${selectedOptions.includes(option.optionId) ? 'selected' : ''} ${selectedVariant?.optionIds.includes(option.optionId) ? 'variant-selected' : ''}`}
                      onClick={() => handleOptionSelect(option.optionId, max, min, optionCategory.optionCategoryId)}
                    >
                      {/* <img src={`https://novosibirsk.grilnica.ru/cdn/${option.iconFileName}`} alt={option.name} /> */}
                      <p>{option.name}</p>
                      {option.price !== 0 && <p>{option.price} руб.</p>}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <button className="add-to-cart-button" onClick={handleAddToCartAlt}>
        Добавить в корзину
      </button>
    </div>
  );
};

export default ProductDetailsPageAlt;
