import React, { useState, useEffect } from 'react';
import './ComboGroupDetails.css';

const ComboProductDetails = ({ comboGroupList, onComboChange }) => {
  const [selectedCombos, setSelectedCombos] = useState({
    products: [],
    amount: 1,
    name: "",
    price: 0,
    programId: "", // programId задается в родительском компоненте
    sourceActionId: "sourceActionId"
  });

  useEffect(() => {
    comboGroupList.forEach(comboGroup => {
      comboGroup.productList.forEach(product => {
        if (product.isDefault) {
          // Автоматически выбираем рекомендованные продукты
          handleProductSelect(comboGroup.comboGroupId, product);
        }
      });
    });
  }, [comboGroupList]);

  useEffect(() => {
    onComboChange([selectedCombos]);
  }, [selectedCombos, onComboChange]);

  const handleProductSelect = (comboGroupId, product, modifierIds = []) => {
    setSelectedCombos(prevCombos => {
      const updatedProducts = prevCombos.products.filter(p => p.comboGroupId !== comboGroupId); // Оставляем только продукты не из этой группы

      const productObject = {
        modifierIds: modifierIds,
        productId: product.productId,
        comment: "",
        amount: 1,
        isExcise: product.isExcise || false,
        comboGroupId: comboGroupId
      };

      updatedProducts.push(productObject); // Добавляем выбранный продукт

      return {
        ...prevCombos,
        products: updatedProducts,
        name: updatedProducts.map(p => p.productId === product.productId ? product.name : "").join(', '),
        price: product.price
      };
    });
  };

  return (
    <div className="combo-group-container">
      {comboGroupList.map(comboGroup => (
        <div key={comboGroup.comboGroupId} className="combo-group">
          <h3>{comboGroup.name}</h3>
          <div className="combo-products">
            {comboGroup.productList.map(product => (
              <div
                key={product.productId}
                className={`combo-product-card ${
                  selectedCombos.products.some(p => p.productId === product.productId) ? 'selected' : ''
                }`}
                onClick={() => handleProductSelect(comboGroup.comboGroupId, product)}
              >
                <img
                  src={`https://novosibirsk.grilnica.ru/cdn/${product.imageFileName}`}
                  alt={product.name}
                  className="combo-product-image"
                />
                <div className="combo-product-info">
                  <h4>{product.name}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComboProductDetails;
