// src/services/api.js
import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
});

export const verifyUser = (initData, user) => {
  return api.post('/verify', { initData, user });
};

export const getCities = () => {
  return api.get('/city');
};

export const getCategories = (user_id) => {
  return api.post(`/category?user_id=${user_id}`); // Получаем категории
};

export const checkOrderStatusBack = (user_id) => {
  return api.post(`/check_order_status?user_id=${user_id}`); // Получаем категории
};

export const getMenuItems = (user_id) => {
  return api.post(`/menu?user_id=${user_id}`); // Получаем товары
};

export const addToCart = (productToAdd) => {
  const userId = window.Telegram.WebApp.initDataUnsafe.user.id; // Получаем user_id из Telegram WebApp
  return api.post(`/add_to_card?user_id=${userId}`, productToAdd);
};

export const submitOrderRating = (order_id, rating, rating_comment) => {
  return api.post(`/rating?order_id=${order_id}&rating=${rating}&rating_text=${rating_comment}`);
};

export const addToCartFromRec = (productToAdd) => {
  const userId = window.Telegram.WebApp.initDataUnsafe.user.id; // Получаем user_id из Telegram WebApp
  return api.post(`/add_to_cart_from_rec?user_id=${userId}`, productToAdd);
};

export const addToCartAlt = (productToAdd) => {
  const userId = window.Telegram.WebApp.initDataUnsafe.user.id; // Получаем user_id из Telegram WebApp
  return api.post(`/add_to_cart_from_rec_fin?user_id=${userId}`, productToAdd);
};

export const fetchProductById = async (productId, user_id) => {
  try {
    const response = await api.get(`/get_product_by_id?product_id=${productId}&user_id=${user_id}`);
    return response.data;
  } catch (error) {
    console.error('Ошибка получения данных о продукте:', error);
    throw error;
  }
};

export const fetchWeightMap = () => {
  return api.post(`/weight-map`); // Получаем категории
};

export const removeFromCart = (userId, productId, quantity = 1) => {
  return api.post(`/remove_from_cart`, null, {
    params: {
      quantity,
      user_id: userId,
      product_id: productId,
    },
  });
};

export const getUserCart = (userId) => {
  return api.get(`/get_cart?user_id=${userId}`);
};

export const createOrder = (userId) => {
  console.log('Sending create order request with user_id:', userId);
  return api.post(`/create_order?user_id=${userId}`);
};

export const getUserOrders = (userId) => {
  return api.get(`/user_orders`, { params: { user_id: userId } });
};

export const getOrderHistoryByUser = (userId) => {
  return api.get(`/get_order_history_by_user`, { params: { user_id: userId } });
};

// Добавляем функцию для подтверждения платежа
export const confirmPayment = async (transactionId) => {
  try {
    const response = await api.get(`/payment/${transactionId}`); // Отправляем запрос по пути /payment/{transactionId}
    return response.status === 200; // Возвращаем true, если статус 200, иначе false
  } catch (error) {
    console.error('Ошибка подтверждения платежа:', error);
    throw error;
  }
};

