// src/components/BackButton.js
import React, { useState } from 'react';
import { ReactComponent as CartIcon } from './arrow.svg'; // Пока используем ту же иконку
import styles from './BackButton.module.css'; // Импортируем стили как модуль

const BackButton = ({ onClick }) => {
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    // Устанавливаем состояние скрытия
    setVisible(false);

    // Добавляем небольшую задержку перед выполнением onClick
    setTimeout(() => {
      onClick();
    }, 300); // Время анимации
  };

  return (
    <div
      className={`${styles.backButton} ${visible ? styles.visible : styles.hidden}`}
      onClick={handleClick}
    >
      <CartIcon className={styles.backIcon} />
    </div>
  );
};

export default BackButton;

