// src/components/CartLoader.js
import React from 'react';
import './CartLoader.css';

const CartLoader = () => (
  <div className="cart-loader">
    <div className="spinner_l"></div>
  </div>
);

export default CartLoader;
